<template>
  <div
    class="outcome-component"
    :class="this.$store.state.mobileView ? 'mobile' : 'desktop'"
  >
    <div class="top-bar">
      <Hud v-if="pageCount < 4" />
      <div class="logo-container" v-else>
        <img
          class="logo-image"
          src="../assets/sponsors/boston-bullies.png"
          alt=""
        />
        Special Thanks To Our Sponsors
      </div>
    </div>
    <div class="bottom-bar" v-if="pageCount < 4">
      <div class="screen-view">
        <div class="mascot-image"></div>
        <div class="final-feedback">
          <div class="final-feedback-text-container">
            <div class="final-feedback-text page1" v-if="pageCount === 1">
              <div>Your final score was</div>
              <div class="feedback-points">{{ $store.state.score }} points</div>
              <div>Congratulations!</div>
            </div>
            <div class="final-feedback-text page2" v-if="pageCount === 2">
              <div class="tip-text">
                <input
                  class="audio"
                  type="image"
                  v-on:click="$store.dispatch('playVO', 'cyberbullying-tips')"
                  :src="require('../assets/play.png')"
                  alt="play sound"
                />
                If you see cyberbullying...
              </div>
              <ul class="list">
                <li>
                  <span class="tip">Don’t respond.</span><br />
                  Don’t share, tag others, or start fighting in the comments.
                </li>
                <li>
                  <span class="tip">Report it.</span><br />
                  You can report cyberbullying on the website, app, or platform
                  and let an adult know what is going on.
                </li>
                <li>
                  <span class="tip">Tell someone.</span><br />
                  Let an adult know what is going on.
                </li>
              </ul>
              <div class="tip-text">If you are being cyberbullied...</div>
              <ul class="list">
                <li>
                  <span class="tip">Know it isn’t your fault.</span><br />
                  Remember, you are not alone.
                </li>
              </ul>
              <div class="link">
                To learn more, visit
                <a href="https://sportsmuseum.org/program/boston-vs-bullies/"
                  >www.bostonvsbullies.org</a
                >
              </div>
            </div>
            <div class="final-feedback-text page3" v-if="pageCount === 3">
              <div>Congratulations!</div>
              <div>Enter your name:</div>
              <input
                id="name"
                class="name-field"
                type="text"
                v-on:change="checkName"
              />
              <div v-if="error" class="error">Name field cannot be empty</div>
            </div>
          </div>
          <button
            v-if="pageCount === 3"
            :class="this.$store.state.mobileView ? 'mobile' : 'desktop'"
            class="red-buttons"
            v-on:click="downloadCert()"
          >
            Download Certificate
          </button>
          <button
            v-if="pageCount < 3 || (pageCount === 3 && showNextButton)"
            class="red-buttons"
            v-on:click="nextScreen"
          >
            Next
          </button>
        </div>
      </div>
    </div>
    <div class="bottom-bar" v-else>
      <div class="sponsor-rows">
        <div class="row row1">
          <img
            src="../assets/sponsors/highland.png"
            alt="Highland Street Foundation logo"
          />
          <img src="../assets/sponsors/nb.png" alt="New Balance logo" />
        </div>
        <div class="row row2">
          <img
            class="long-img"
            src="../assets/sponsors/mabel-louise.png"
            alt="Mabel Louise Riley Foundation logo"
          />
          <img
            src="../assets/sponsors/liberty-mutual.png"
            alt="Liberty Mutual logo"
          />
        </div>
        <div class="row row3">
          <img
            src="../assets/sponsors/bruins.png"
            alt="Boston Bruins Foundation logo"
          />
          <img
            src="../assets/sponsors/red-sox.png"
            alt="Red Sox Foundation logo"
          />
          <img
            src="../assets/sponsors/celtics.png"
            alt="Boston Celtics Shamrock Foundation logo"
          />
          <img src="../assets/sponsors/tjx.png" alt="TJX logo" />
          <img
            class="medium-img"
            src="../assets/sponsors/acushnet.png"
            alt="Acushnet Company logo"
          />
        </div>
        <div class="row row4">
          <img
            class="medium-img"
            src="../assets/sponsors/macys.png"
            alt="Macy's logo"
          />
          <img
            src="../assets/sponsors/pats.png"
            alt="The New England Patriots Charitable Foundation logo"
          />
          <img
            class="medium-short-img"
            src="../assets/sponsors/massport.png"
            alt="Massport logo"
          />
          <img
            src="../assets/sponsors/doug-flutie.png"
            alt="Doug Flutie Jr. Foundation for Autism logo"
          />
          <img
            class="medium-short-img"
            src="../assets/sponsors/fuller-foundation.jpg"
            alt="The Fuller Foundation logo"
          />
          <img
            src="../assets/sponsors/ride-logo.png"
            alt="The Rodman Ride for Kids logo"
          />
        </div>
        <div class="row row5">
          <img
            class="medium-short-img"
            src="../assets/sponsors/arbella-foundation.png"
            alt="Arbella Insurance Foundation logo"
          />
          <img
            class="medium-short-img"
            src="../assets/sponsors/bank-of-america.png"
            alt="Bank of America logo"
          />
          <img
            src="../assets/sponsors/scc-logo.png"
            alt="Speedway Children's Charities logo"
          />
          <img
            src="../assets/sponsors/wells-fargo.png"
            alt="Wells Fargo logo"
          />
          <img
            src="../assets/sponsors/sec-logo.png"
            alt="Someone Else's Child logo"
          />
          <img
            src="../assets/sponsors/the-black-heart-logo.png"
            alt="The Black Heart Foundation logo"
          />
        </div>
      </div>
      <div class="button-container">
        <button class="red-buttons" v-on:click="playAgain">Play Again!</button>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import Hud from "@/components/Hud.vue";
import { PDFDocument, rgb } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";
import download from "downloadjs";
export default {
  name: "Outcome",
  components: {
    Hud,
  },
  data() {
    return {
      pageCount: 1,
      error: true,
      name: "",
      showNextButton: false,
    };
  },
  mounted() {
    this.$store.commit("getFocusableItems");
    if (this.$store.state.audio) {
      this.$store.dispatch("playVO", "win-game");
    }
  },
  methods: {
    nextScreen() {
      this.$store.commit("stopAllSounds");
      this.pageCount += 1;
      if (this.pageCount === 2 && this.$store.state.audio) {
        this.$store.dispatch("playVO", "cyberbullying-tips");
      }
    },
    checkName() {
      $("#name").val() === "" ? (this.error = true) : (this.error = false);
    },
    downloadCert() {
      if (this.error) {
        console.log("not submitting");
      } else {
        this.modifyPdf($("#name").val());
      }
    },
    async modifyPdf(name) {
      // Fetch an existing PDF document
      const url = window.location.origin + "/certificate.pdf";
      const existingPdfBytes = await fetch(url).then((res) =>
        res.arrayBuffer()
      );

      // Load a PDFDocument from the existing PDF bytes
      const pdfDoc = await PDFDocument.load(existingPdfBytes);

      // Fetch custom font
      const font = window.location.origin + "/Quicksand-Regular.ttf";
      const fontBytes = await fetch(font).then((res) => res.arrayBuffer());

      // Register the `fontkit` instance
      pdfDoc.registerFontkit(fontkit);

      // Embed our custom font in the document
      const customFont = await pdfDoc.embedFont(fontBytes);

      // Get the first page of the document
      const pages = pdfDoc.getPages();
      const firstPage = pages[0];

      // Get the width and height of the first page
      const { width, height } = firstPage.getSize();

      const text = name;
      const textSize = 35;
      const textWidth = customFont.widthOfTextAtSize(text, textSize);
      const textHeight = customFont.heightAtSize(textSize);

      // Draw a string of text diagonally across the first page
      firstPage.drawText(text, {
        x: width / 2 - textWidth / 2,
        y: height / 2 - textHeight / 2 - 45,
        size: textSize,
        font: customFont,
        color: rgb(0.2, 0.23, 0.32),
      });

      // Serialize the PDFDocument to bytes (a Uint8Array)
      const pdfBytes = await pdfDoc.save();

      // Trigger the browser to download the PDF document
      await download(pdfBytes, "certificate.pdf", "application/pdf");
      this.showNextButton = true;
    },
    playAgain() {
      this.$store.commit("initGame");
      this.$store.commit("navigate", {
        route: "Title",
        data: { title: "Boston vs. Bullies Academy for Internet Champions" },
      });
    },
  },
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped>
.screen-view {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 3fr);
}

.mascot-image {
  background-image: url("../assets/characters/mascot.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom center;
}

.final-feedback {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.final-feedback-text-container {
  width: 80%;
  border-radius: 30px;
  border-style: solid;
  border-color: var(--top-bar-blue);
  border-width: 5px 5px 15px 5px;
}

.final-feedback-text {
  color: #155196;
  border-radius: 25px 25px 15px 15px;
  background-color: var(--yellow);
  padding: 4vh;
}

.final-feedback-text.page1,
.final-feedback-text.page3,
.final-feedback-text.page4 {
  font-variation-settings: "wght" 900;
}

.final-feedback-text > div {
  margin: 1vh 0;
}

.feedback-points {
  font-size: clamp(12px, 4vw, 3vh);
}

.list {
  text-align: left;
  margin: 0.5em 0;
}

li,
.link {
  font-size: min(2vw, 25px);
}

.tip-text {
  display: flex;
}

.tip,
.tip-text {
  font-variation-settings: "wght" 900;
}

.mobile > * .feedback-points {
  font-size: clamp(12px, 5vw, 3vh);
}

.outcome-component {
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  font-size: clamp(12px, 3vw, 3vh);
  grid-template-columns: minmax(0, 2fr) minmax(0, 3fr);
  grid-template-rows: minmax(0, 2fr) minmax(0, 7fr);
  justify-content: center;
  justify-items: center;
  align-items: center;
  background-color: var(--off-white);
}

.outcome-component.mobile {
  font-size: clamp(12px, 4vw, 3vh);
}

.top-bar {
  grid-column-start: 1;
  grid-column-end: 3;
  width: 100%;
  height: 100%;
  background-color: var(--top-bar-blue);
}

.bottom-bar {
  grid-column-start: 1;
  grid-column-end: 3;
  width: 100%;
  height: 100%;
}

.modal {
  flex-direction: column;
  justify-content: space-evenly;
  height: 50vh;
  width: 75vw;
  font-size: clamp(12px, 4vw, 3vh);
  display: flex;
  z-index: 1;
  background-color: white;
  align-items: center;
  border-radius: 25px;
  border-style: solid;
  border-width: 2vw;
  border-color: var(--text-blue);
}

.mobile > .modal {
  font-size: clamp(12px, 6vw, 3vh);
}

input[type="text"] {
  font-size: clamp(12px, 3vw, 3vh);
  font-family: "Quicksand", Arial, sans-serif;
}

.mobile > * input[type="text"] {
  font-size: clamp(12px, 4vw, 3vh);
}

.error {
  font-size: clamp(12px, 2vw, 3vh);
  color: red;
}

.mobile > * .error {
  font-size: clamp(12px, 3.5vw, 3vh);
}

.name-field {
  width: 100%;
}

.row,
.logo-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-variation-settings: "wght" 700;
  color: white;
  height: 100%;
  max-width: 100%;
}

.logo-image {
  max-height: 85%;
  max-width: calc(100vw / 3);
}

.row > img {
  max-height: 100%;
  max-width: calc((100vw / 2) - 2vh);
}

.long-img {
  max-height: 30% !important;
}

.medium-img {
  max-height: 50% !important;
}

.medium-short-img {
  max-height: 75% !important;
}

/* .row1 > img,
.row2 > img {
  max-width: calc((100vw / 2) - 2vh);
} */

.row3 > img,
.row4 > img,
.row5 > img {
  max-width: calc((100vw / 6) - 2vh);
}

.sponsor-rows {
  align-self: flex-start;
  grid-column-start: 1;
  grid-column-end: 3;
  height: 85%;
  width: 100%;
  box-sizing: border-box;
  padding: 2vh;
  display: grid;
  /* grid-template-rows:
    minmax(0, 2fr) minmax(0, 1.5fr) minmax(0, 1fr) minmax(0, 1fr)
    minmax(0, 1fr); */
  grid-template-rows:
    minmax(0, 300px) minmax(0, 100px) minmax(0, 70px) minmax(0, 70px)
    minmax(0, 70px);
  grid-gap: 2.5vh;
  grid-template-columns: minmax(0, 1fr);
}

.button-container {
  height: 15%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
